<script setup lang="ts">
import { onMounted, ref } from 'vue';
import {RealtyObject} from "@/shared/api/gproperty-service";
import Card from "@/entities/card/ui/card.vue";
import CardListSkeleton from "@/shared/ui/card-list-skeleton.vue";

const mapContainer = ref(null); // Ссылка на контейнер карты


type Props = {
  cards: RealtyObject[];
  isLoading: boolean
};

const props = defineProps<Props>();

const emit = defineEmits(["close"]);

const onMapClose = () => {
  emit('close')
}

// Функция для динамической загрузки SDK Яндекс.Карт
function loadYandexMapScript() {
  return new Promise((resolve, reject) => {
    if (window.ymaps) { // Проверка, если SDK уже загружен
      resolve();
      return;
    }

    const script = document.createElement("script");
    // script.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
    script.src = "https://api-maps.yandex.ru/2.1/?apikey=e88ba690-307c-4ce3-af43-e04f87140abd&lang=ru_RU";
    script.async = true;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

onMounted(async () => {
  try {
    await loadYandexMapScript(); // Загружаем SDK Яндекс.Карт
    let placeMarkCollection = []

    // После загрузки SDK создаем карту
    window.ymaps.ready(() => {
      const myMap = new window.ymaps.Map(mapContainer.value, {
        center: [55.751244, 37.618423], // Координаты центра (Москва)
        zoom: 10,
        behaviors: ['default', 'scrollZoom'],
      });

      const clusterer = new window.ymaps.Clusterer({
        clusterDisableClickZoom: true,
        clusterOpenBalloonOnClick: true,
        clusterBalloonContentLayout: 'cluster#balloonAccordion',
        clusterBalloonPanelMaxMapArea: 0,
      });

      // clusterer.events.add('balloonopen', function (e) {
      //   var clusterPlacemark = e.get('cluster');
      //   console.log('clusterPlacemark: ', clusterPlacemark)
      // });

      clusterer.balloon.events.add('open', (e) => {
        placeMarkCollection.forEach((item) => {
          item.options.unset('preset');
        })
      })

      let container1 = document.querySelector('#cardListMap');
      let cardList1 = container1.querySelectorAll('.card-wr-a');

      clusterer.events.add('click', function (e) {
        var cluster = e.get('target');//.state.get('activeObject')
        var stateMonitor = new window.ymaps.Monitor(cluster.state);
        stateMonitor.add('activeObject', function(activeObject) {
          if (!activeObject) return
          console.log('activeObject: ', activeObject.properties);
          let clusterString = activeObject.properties._data.clusterCaption;
          let id = clusterString.split(" ")[0].slice(0,-1).trim();
          for (let k = 0; k < cardList1.length; k++) {
            cardList1[k].style.borderRadius = '0'
            cardList1[k].style.boxShadow = 'none'
          }
          for (let i = 0; i < cardList.length; i++) {
            if (cardList1[i].querySelector('#asId').innerText == id) {


              cardList[i].style.boxShadow = '0 0 10px rebeccapurple'
              cardList[i].style.borderRadius = '10px'
              let container = document.querySelector('#cardListMap');
              let coords = cardList[i].offsetTop
              coords = coords - 150;
              container.scroll({
                top: coords,
                left: 0,
                behavior: 'smooth'
              });
            }
          }

        });

      });

      const getPointData = function (index) {
        return {
          placemarkId: index,
          balloonContentBody: `
                                        <div style="font-weight: 600; margin-bottom: 10px; border-bottom: 1px solid red; ">${cardList[index].querySelector('#premType').innerText}</div>
                                        <div class="card-size">
                                            ${cardList[index].querySelector('.card-size').innerText}
                                        </div>
                                            ${cardList[index].querySelector('.for-map1').innerText}
                                            ${cardList[index].querySelector('.for-map3').innerText}<br>
                                            ${cardList[index].querySelector('.for-map2').innerText}
                                            ${cardList[index].querySelector('.for-map4').innerText.toLocaleString()}
                    `,
          clusterCaption: `${cardList[index].querySelector('#asId').innerText}. ${cardList[index].querySelector('.room-adress').innerText}`
        };
      };

      let container = document.querySelector('#cardListMap');
      let adresList = container.querySelectorAll('.card-address');
      let cardList = container.querySelectorAll('.card-wr-a');
      let cardTitleCount = document.querySelector('#test');
      cardTitleCount.innerText = `Найдено ${cardList.length} предложений:`

      for (let i = 0; i < adresList.length; i++) {

        let cardBtn = cardList[i].querySelector('#goToCardBtn')
        cardBtn.style.display = 'block'
        cardList[i].querySelector('#goToCardBtn').addEventListener('click', () => {
          let cardLink = cardList[i].href
          window.location = cardLink
        })

        let geocoder = window.ymaps.geocode(adresList[i].innerText);
        geocoder.then(
            function (res) {

              let coordinates = res.geoObjects.get(0).geometry.getCoordinates();
              let placemark = new window.ymaps.Placemark(
                  coordinates, getPointData(i),
                  {
                    'preset': 'islands#redDotIcon'
                  }
              );


              console.log('placemark: ', placemark)
              placemark.options.unset('preset');
              placeMarkCollection.push(placemark)


              placemark.events.add('click', function (e) {
                for (let k = 0; k < cardList.length; k++) {
                  cardList[k].style.borderRadius = '0'
                  cardList[k].style.boxShadow = 'none'
                }
                cardList[i].style.boxShadow = '0 0 10px rebeccapurple'
                cardList[i].style.borderRadius = '10px'
                let container = document.querySelector('#cardListMap');
                let coords = cardList[i].offsetTop
                coords = coords - 150;
                container.scroll({
                  top: coords,
                  left: 0,
                  behavior: 'smooth'
                });
                placeMarkCollection.forEach((item) => {
                  item.options.unset('preset');

                })
                e.get('target').options.set('preset', 'islands#redIcon');
              });


              cardList[i].addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();
                for (let j = 0; j < cardList.length; j++) {
                  cardList[j].style.borderRadius = '0'
                  cardList[j].style.boxShadow = 'none'
                }
                cardList[i].style.boxShadow = '0 0 10px rebeccapurple'
                cardList[i].style.borderRadius = '10px'
                placeMarkCollection.forEach((item) => {
                  item.options.unset('preset');

                })
                placemark.options.set('preset', 'islands#redIcon');
                var objectState = clusterer.getObjectState(placemark);
                if (objectState.isClustered) {
                  objectState.cluster.state.set('activeObject', placemark);
                  clusterer.balloon.open(objectState.cluster);
                } else if (objectState.isShown) {
                  placemark.balloon.open();
                }
              })

              myMap.geoObjects.add(placemark);
            }
        );
      }
      setTimeout(() => {
        clusterer.add(placeMarkCollection);
        myMap.geoObjects.add(clusterer);
      }, 1300)
    });
  } catch (error) {
    console.error("Ошибка при загрузке Яндекс.Карт:", error);
  }

});
</script>

<template>
  <div id="feedBackModalWr" style="display: block;"></div>
  <div id="feedBackModal" style="z-index: 20; width: 80%; left: 10%; background: rgb(245, 246, 247); display: block;">
    <h3 style="text-align: center; font-size: 25px">Выберите помещение на карте</h3> <span @click="onMapClose" class="close-btn">X</span>
    <div style="display: flex;" id="yMapWr">
      <div ref="mapContainer" class="map-cont" style="width: 100%; height: 800px;"></div>
        <div style="padding: 40px;" id="mediaModalMap">
        <div id="test" style="margin-left: 10px; text-align: left; margin-bottom: 5px">Найдено 12 предложений:</div>
        <div style="display: flex; flex-wrap: wrap; width: 600px; gap: 20px; height: 800px; overflow-x: auto; scroll-snap-type: x mandatory; padding-left: 10px;" id="cardListMap">
          <template v-if="!props.isLoading">
            <Card v-for="card in props.cards" :commonData="card" :key="card.id" />
          </template>
          <template v-else>
            <CardListSkeleton v-for="i in 7"/>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.map-cont {
  margin-top: 50px;
}
.close-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  font-weight: 900;
}
/* Настраиваем размеры контейнера карты */
</style>


