<script setup lang="ts">

import {onMounted, ref} from "vue";
import {
  RealtyObject,
  useApiRequest,
  useApiRequestSimilarOffers
} from "@/shared/api/gproperty-service";
import {formatCurrency} from "../../../shared/utils";
import {useRouter} from "vue-router";
import Card from "@/entities/card/ui/card.vue";
import CardListSkeleton from "@/shared/ui/card-list-skeleton.vue";
const { result, isLoading, error, makeRequest } = useApiRequest();
const {
  result: resultSimilarOffers,
  isLoading: isLoadingSimilarOffers,
  makeRequest: makeRequestSimilarOffers
} = useApiRequestSimilarOffers();

const realtyObj = ref<RealtyObject>()
const photoIndex = ref(0)
const similarOffers = ref<RealtyObject[]>([])


const router = useRouter()

onMounted(async () => {
  await makeRequest(router.currentRoute.value.params.id as string)
  realtyObj.value = {...result.value?.result as RealtyObject}
  if (result.value?.result?.id) {
    await makeRequestSimilarOffers({'realty_object_id': result.value?.result?.id})
    similarOffers.value = resultSimilarOffers.value?.result ? [...resultSimilarOffers.value?.result as RealtyObject[]] : []
  } else {
    similarOffers.value = []
  }

})

const onRightArrow = () => {
  if (realtyObj.value?.photos?.length && photoIndex.value < realtyObj.value?.photos.length -1) {
    photoIndex.value++
  } else {
    photoIndex.value = 0
  }
}

const onLeftArrow = () => {
  if (realtyObj.value?.photos?.length && photoIndex.value > 0) {
    photoIndex.value--
  } else {
    photoIndex.value = realtyObj.value?.photos?.length ? realtyObj.value?.photos.length - 1 : 0
  }
}
</script>

<template>
  <div class="spinner" v-if="isLoading"></div>
  <div class="inside-page-card-wr" v-else>
    <h2 class="main-title main-title_inside " style="text-transform: uppercase;">{{realtyObj?.title}}</h2>
    <div class="card-lot-title" style="text-transform: uppercase;">{{realtyObj?.premises_type}} (ЛОТ {{realtyObj?.lot}})</div>
    <div style="display: flex; justify-content: space-between;">
      <div style="display: flex; font-size: 16px">
        <div class="inside-adress" style="margin-right: 40px; margin-left: 40px;">
         {{realtyObj?.full_address}}
        </div>
        <div class="inside-metro">
          {{realtyObj?.underground}}
        </div>
      </div>
    </div>
    <div style="display: flex; width: 100%; margin-top: 40px;" class="slider-and-desc-wr-media">
      <div class="slider-and-desc-wr">
        <div class="slider-inside">

          <div
              class="slider-item"
              :style="{backgroundImage: `url(${realtyObj?.photos[photoIndex]})`}"
              style="width: 652px; height: 460px; display: block; transition: all 0.5s ease 0.5s; background-repeat: no-repeat; background-size: contain; background-position: center center;">
          </div>

          <div class="right-arrow" @click="onRightArrow"></div>
          <div class="left-arrow" @click="onLeftArrow"></div>
        </div>
        <div class="get-help-btn">Получить консультацию</div>

        <div class="card-description" style="margin-bottom: 20px">
          <div class="card-description-title">
            Описание
          </div>
          <div class="card-description-text">
            {{realtyObj?.description}}
          </div>
        </div>
      </div>
      <div class="description-wr" style="margin-left: 30px; width: 100%">
        <div class="top-desc">
          <ul>
            <li class="desc-list-item">
              <div>
                {{realtyObj?.selling_price ? 'Цена' : 'Аренда в месяц'}}:
              </div>
              <div>
                {{realtyObj?.selling_price ? 'Цена за м²' : 'Цена за м² в год'}}:
              </div>
            </li>
            <li class="desc-list-item">
              <div style="font-size: 16px; font-weight: 600;">
                <span class="locale-price">{{formatCurrency(realtyObj?.rent_per_month || realtyObj?.selling_price)}}</span>
                <span style="color: #ff3b3b;"> ₽</span>
              </div>
              <div style="font-size: 16px; font-weight: 600;">
                <span class="locale-price">{{formatCurrency(realtyObj?.rent_price_m2_year || realtyObj?.selling_price_m2)}}</span>
                <span style="color: #ff3b3b;"> ₽</span>
              </div>
            </li>
            <li class="desc-list-item desc-list-item_square">
              <div>
                Площадь:
              </div>
              <div style="font-weight: 600;" id="squareItem">
                {{realtyObj?.total_area}} м²
              </div>
            </li>
            <li class="desc-list-item desc-list-item_type">
              <div>
                Тип здания:
              </div>
              <div style="font-weight: 600;" id="typeItem">
                {{realtyObj?.building_type}}
              </div>
            </li>
            <li class="desc-list-item desc-list-item_floor">
              <div>
                Этаж:
              </div>
              <div style="font-weight: 600;" id="floorItem">
                {{realtyObj?.floor}}
              </div>
            </li>
            <li v-if="realtyObj?.floor_quantity" class="desc-list-item desc-list-item_floor-count">
              <div>
                Количество этажей:
              </div>
              <div style="font-weight: 600;">
                {{realtyObj?.floor_quantity}}
              </div>
            </li>
            <li class="desc-list-item desc-list-item_layout">
              <div>
                Планировка:
              </div>
              <div style="font-weight: 600;" id="layoutItem">
                {{realtyObj?.layout}}
              </div>
            </li>
            <li class="desc-list-item desc-list-item_paint">
              <div>
                Отделка:
              </div>
              <div style="font-weight: 600;">
                {{realtyObj?.finishing}}
              </div>
            </li>
            <li v-if="realtyObj?.electricity_power" class="desc-list-item desc-list-item_vit">
              <div>
                Мощность (кВт):
              </div>
              <div style="font-weight: 600;">
                {{realtyObj?.electricity_power}}
              </div>
            </li>
            <li v-if="realtyObj?.ceiling_height" class="desc-list-item desc-list-item_cel">
              <div>
                Высота потолков (м):
              </div>
              <div style="font-weight: 600;">
                {{realtyObj?.ceiling_height}}
              </div>
            </li>
            <li v-if="realtyObj?.hood" class="desc-list-item desc-list-item_vit">
              <div>
                Вытяжка:
              </div>
              <div style="font-weight: 600;" id="vitItem">
                {{realtyObj?.hood}}
              </div>
            </li>
            <li class="desc-list-item desc-list-item_parking">
              <div>
                Парковка:
              </div>
              <div style="font-weight: 600;" id="parkingItem">
                {{realtyObj?.parking}}
              </div>
            </li>
            <li v-if="realtyObj?.parking_space_quantity" class="desc-list-item desc-list-item_parking">
              <div>
                Количество мест:
              </div>
              <div style="font-weight: 600;" id="parkingItem">
                {{realtyObj?.parking_space_quantity}}
              </div>
            </li>
            <li v-if="realtyObj?.parking_space_cost" class="desc-list-item desc-list-item_parking">
              <div>
                Количество мест:
              </div>
              <div style="font-weight: 600;" id="parkingItem">
                {{realtyObj?.parking_space_cost}}
              </div>
            </li>
          </ul>
        </div>
        <div class="bot-desc">
          <div class="bot-desc-title">
            Коммерческие условия
          </div>
          <ul style="font-size: 14px">
            <li class="desc-list-item" style="margin-top: 10px;">
              <div>
                Торг:
              </div>
              <div style="font-weight: 600;">
                {{realtyObj?.bargain}}
              </div>
            </li>
            <li v-if="realtyObj?.tenant" class="desc-list-item" style="margin-top: 15px;" id="tenantListItem">
              <div>
                Арендатор:
              </div>
              <div style="font-weight: 600;" id="tenant">
                {{realtyObj?.tenant}}
              </div>
            </li>
            <li class="desc-list-item" style="margin-top: 15px;" id="rentContractTermListItem">
              <div>
                Срок договора аренды:
              </div>
              <div style="font-weight: 600;" id="rentContractTerm">
                {{realtyObj?.rent_contract_term}}
              </div>
            </li>

            <li v-if="realtyObj?.tax" class="desc-list-item" style="margin-top: 15px;">
              <div>
                Налоги:
              </div>
              <div style="font-weight: 600;">
                {{realtyObj?.tax}}
              </div>
            </li>

            <li v-if="realtyObj?.income_per_month" class="desc-list-item" style="margin-top: 15px;">
              <div>
                Доход:
              </div>
              <div style="font-weight: 600;">
                Доход <span id="dohod">{{formatCurrency(realtyObj?.income_per_month)}}</span> ₽/МЕС
              </div>
            </li>

            <li v-if="realtyObj?.payback" class="desc-list-item" style="margin-top: 15px;">
              <div>
                Окупаемость:
              </div>
              <div style="font-weight: 600;">
                {{realtyObj?.payback}} лет
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div v-if="similarOffers?.length || isLoadingSimilarOffers" class="red-title" style="margin-top: 40px; margin-bottom: 40px">Похожие предложения</div>

  <div v-if="isLoadingSimilarOffers || similarOffers?.length" class="room-cards-wr">
    <template v-if="!isLoadingSimilarOffers && similarOffers?.length">
      <Card v-for="offer in similarOffers" :commonData="offer" :key="offer.id" />
    </template>
    <template v-else>
      <CardListSkeleton v-for="i in 4"/>
    </template>
  </div>
</template>

<style scoped>
.spinner {
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  margin-top: 100px;
  margin-bottom: 80px;
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #ff3b3b;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
