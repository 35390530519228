import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-wr" }
const _hoisted_2 = { class: "filters" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "custom-selection" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "custom-selection" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "custom-selection" }
const _hoisted_9 = { class: "bot-buttons" }

import DefaultButton from "@/shared/components/default-button";
import {ref} from "vue";
import MapModal from "@/features/map-modal/ui/map-modal.vue";
import {RealtyObject} from "@/shared/api/gproperty-service";
import InputDropdown from "@/shared/components/input-dropdown/ui/input-dropdown.vue";
import {useRouter} from "vue-router";
type Props = {
  cards: RealtyObject[];
  isLoading: boolean
};


export default /*@__PURE__*/_defineComponent({
  __name: 'main-filter',
  props: {
    cards: {},
    isLoading: { type: Boolean }
  },
  setup(__props: any) {

const defaultFilters = {
  purpose: 'Арендный бизнес',
  premises_type: null,
  region: null,
}

const router = useRouter()

const areaRange = ref({
  minValue: null,
  maxValue: null
});

const priceRange = ref({
  minValue: null,
  maxValue: null
});

const select1 = ref(null);
const select2 = ref(null);
const select3 = ref(null);

const selectItem = (item: any) => {
  filters.value.purpose = item.title; // Устанавливаем выбранный элемент
  select1.value?.blur()
};
const selectItem2 = (item: any) => {
  filters.value.premises_type = item.title; // Устанавливаем выбранный элемент
  select2.value?.blur()
};
const selectItem3 = (item: any) => {
  filters.value.region = item.title; // Устанавливаем выбранный элемент
  select3.value?.blur()
};

const props = __props;

const filters = ref({...defaultFilters})

const isMapModalOpen = ref(false)

const onMapOpen = () => {
  isMapModalOpen.value = true
}

const onSearchClick = async () => {
  await router.push({
    name: 'catalog',
    query: {
      page_number: 1,
      ...filters.value,
      price_from: priceRange.value.minValue,
      price_to: priceRange.value.maxValue,
      total_area_from: areaRange.value.minValue,
      total_area_to: areaRange.value.maxValue
    }
  });

}

return (_ctx: any,_cache: any) => {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_select, {
            ref_key: "select1",
            ref: select1,
            modelValue: filters.value.purpose,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filters.value.purpose) = $event)),
            class: "custom-select",
            placeholder: "Цель",
            items: ['Продажа', 'Аренда', 'Арендный бизнес'],
            variant: "outlined"
          }, {
            item: _withCtx(({ item }) => [
              _createElementVNode("div", {
                class: "custom-item",
                onClick: ($event: any) => (selectItem(item))
              }, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ], 8, _hoisted_3)
            ]),
            selection: _withCtx(({ item, index }) => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_select, {
            ref_key: "select2",
            ref: select2,
            modelValue: filters.value.premises_type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((filters.value.premises_type) = $event)),
            placeholder: "Тип помещения",
            items: ['Торговое помещение', 'Здание', 'Офисное помещение'],
            variant: "outlined"
          }, {
            item: _withCtx(({ item }) => [
              _createElementVNode("div", {
                class: "custom-item",
                onClick: ($event: any) => (selectItem2(item))
              }, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ], 8, _hoisted_5)
            ]),
            selection: _withCtx(({ item, index }) => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(_component_v_select, {
            ref_key: "select3",
            ref: select3,
            modelValue: filters.value.region,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((filters.value.region) = $event)),
            placeholder: "Любой город",
            items: ['Любой город', 'Москва', 'Подмосковье'],
            variant: "outlined"
          }, {
            item: _withCtx(({ item }) => [
              _createElementVNode("div", {
                class: "custom-item",
                onClick: ($event: any) => (selectItem3(item))
              }, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ], 8, _hoisted_7)
            ]),
            selection: _withCtx(({ item, index }) => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _createVNode(InputDropdown, {
            modelValue: areaRange.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((areaRange).value = $event)),
            label: "Площадь, м²"
          }, null, 8, ["modelValue"]),
          _createVNode(InputDropdown, {
            modelValue: priceRange.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((priceRange).value = $event)),
            label: "Стоимость, ₽"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_unref(DefaultButton), { onClick: onMapOpen }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("На карте")
            ])),
            _: 1
          }),
          _createVNode(_unref(DefaultButton), {
            filled: "",
            onClick: onSearchClick
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Поиск")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    (isMapModalOpen.value)
      ? (_openBlock(), _createBlock(MapModal, {
          key: 0,
          isLoading: _ctx.isLoading,
          cards: _ctx.cards,
          onClose: _cache[5] || (_cache[5] = ($event: any) => (isMapModalOpen.value = false))
        }, null, 8, ["isLoading", "cards"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})