<script setup lang="ts">
import {RealtyObject} from "@/shared/api/gproperty-service";
import {onMounted} from "vue";
import {formatCurrency} from "../../../shared/utils";

type Props = {
  commonData: RealtyObject;
};

const props = defineProps<Props>();

onMounted(() => {
  console.log('card: ', props.commonData)
})
</script>

<template>
  <a :href="`/realty-object/${commonData.id}`" class="card-wr-a">
    <div v-if="commonData.payback || commonData.income_per_month" class="top-block-wr">
      <div v-if="commonData.payback" class="top-block-item">Окупаемость <br/> {{commonData.payback}} лет</div>
      <div v-if="commonData.income_per_month" class="top-block-item">Доход <br/> {{formatCurrency(commonData.income_per_month)}} ₽/МЕС</div>
    </div>
    <div class="room-card-wr">

      <div class="room-card-top" :style="{backgroundImage: `url(${props.commonData.photos[0]})`}" >
      </div>

      <div class="room-card-top-front">
        <div class="room-card-top-front-title-wr">
          <div style="display: flex; justify-content: space-between;">
            <div class="for-map1">
             {{commonData.selling_price ? 'Цена' : 'Аренда в месяц'}}:
            </div>
            <div class="for-map2">
              {{commonData.selling_price ? 'Цена за м²' : 'Цена за м² в год'}}:
            </div>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <div class="for-map3">
              <span style="font-size: 17px; font-weight: 700;" class="locale-price">{{formatCurrency(commonData.selling_price || commonData.rent_per_month)}}</span>
              <span style="font-size: 17px; color: #ff3b3b;">
                            ₽
                        </span>
            </div>
            <div class="for-map4">
              <span style="font-size: 17px; font-weight: 700;" class="locale-price">{{formatCurrency(commonData.selling_price_m2 || commonData.rent_price_m2_year)}}</span>
              <span style="font-size: 17px; color: #ff3b3b;">
                            ₽
                        </span>
            </div>
          </div>
        </div>
      </div>
      <div class="room-card-bottom">
        <div style="display: flex; justify-content: space-between; position: absolute; top: 10px; left: 0; padding: 5px; width: 100%;">
        </div>
        <div class="room-adress card-info">
          {{commonData.title}}
        </div>
        <div class="options-wr">
          <div class="card-address card-info">
            {{commonData.full_address}}
          </div>
          <div class="card-metro card-info">
            {{commonData.underground}}
            ({{commonData.minute_to_underground}})
          </div>
          <div class="card-size card-info">
            Площадь - {{commonData.total_area}} м²
          </div>
          <div id="asId" style="display: none">81</div>
          <div id="premType" style="display: none">Офисное помещение</div>
        </div>
        <button id="goToCardBtn" style="display: none;">Перейти</button>
      </div>
    </div>
  </a>
</template>

<style scoped>
.card-info {
  text-align: left;
}
.card-wr-a {
  height: fit-content;
}
.top-block-wr {
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  gap: 10px;
}
.top-block-item {
  text-align: start;
  font-family: 'Gilroy', sans-serif;
  padding: 3px;
  padding-left: 10px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  text-transform: uppercase;
  width: 100%;
  max-width: 130px;
  background-color: #ff3b3b;
}
</style>
