import { ref } from 'vue';
import {baseUrl, defaultToken} from "@/shared/api/gproperty-service/config";

export function useApiRequestSimilarOffers() {
    const result = ref<any>(null);
    const isLoading = ref(false);
    const error = ref<Error | null>(null);

    const makeRequest = async (params: any) => {
        const url = baseUrl;
        const authToken = defaultToken; // Замените на актуальный токен
        const payload = {
            method: "realty_object_similar_offers",

            params: params
        };

        isLoading.value = true;
        error.value = null;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Accept-Encoding': 'gzip, deflate, br, zstd',
                    'Accept-Language': 'ru,en;q=0.9',
                    'Auth-Token': authToken,
                    'Cache-Control': 'no-cache',
                    'Connection': 'keep-alive',
                    'Content-Type': 'application/json',
                    'Origin': 'https://app.aprope.ru',
                    'Pragma': 'no-cache',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`Ошибка: ${response.status}`);
            }

            const data = await response.json();
            result.value = data;
        } catch (err) {
            error.value = err as Error;
            console.error('Ошибка запроса:', error.value);
        } finally {
            isLoading.value = false;
        }
    };

    return {
        result,
        isLoading,
        error,
        makeRequest,
    };
}
