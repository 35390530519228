<script setup lang="ts">

</script>

<template>
  <div class="top-wr" style="height: 350px;">
    <div class="home">
      <h2 class="main-title" style="margin-top: -60px;">Контакты</h2>
      <div style="font-size: 14px; color: white; text-align: center;">
        Главная &gt; Контакты
      </div>
    </div>

  </div>
  <div class="map-and-form">
    <div style="width: 65%;">
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A2333d9337f6cd281cd7e54f5e982c036a2edcf933c30383660fe767ae7a62c5d&amp;source=constructor" width="100%" height="100%" frameborder="0"></iframe>
    </div>
    <div style="width: 35%; padding: 55px; text-align: center; background: white;">
      <div style="color: #ff3b3b; font-size: 25px; font-weight: bold; margin-bottom: 20px;">
        Оставьте заявку
      </div>
      <div style="margin-bottom: 25px;">
        и наши специалисты свяжутся с вами в течение 30 минут
      </div>
      <ul style="display: flex; flex-wrap: wrap; justify-content: space-between; gap: 20px;">
        <li>
          <input type="text" placeholder="Имя" class="form-inp">
        </li>
        <li>
          <input type="text" placeholder="Ваш email" class="form-inp">
        </li>
        <li>
          <input type="phone" placeholder="Телефон" class="form-inp">
        </li>
        <li>
          <input type="text" placeholder="Тема" class="form-inp">
        </li>
        <li>
          <textarea name="" id="" cols="30" rows="10" placeholder="Сообщение" class="form-inp" style="min-height: 105px;"></textarea>
        </li>
      </ul>
      <button style="border: none; outline: none; width: 158px; height: 40px; text-align: center; text-transform: uppercase; color: white; background: #ff3b3b; border-radius: 5px; margin-top: 20px;" id="sendConBtn">отправить</button>
      <div style="background: #f3f6fa; width: 100%; margin-top: 100px; text-align: left; padding: 35px 35px; padding-bottom: 10px;">
        <div style="margin-bottom: 20px;">
          г. Москва, Малый Толмачёвский переулок, 8/11с1
        </div>
        <div style="margin-bottom: 20px;">
          +7 915 337 66 65
        </div>
        <div style="margin-bottom: 20px;">
          info@aprope.ru
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.home{
  background-image: url(../../../assets/image/lg2.jpg) !important;
  height: 510px;
  margin-top: -131px;
  background-position-y: -650px;
  background-position-x: 0px;
  background-size: cover;

  font-size: 36px;
  font-family: 'Gilroy', sans-serif;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
</style>
