import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 0,
  class: "top-block-wr"
}
const _hoisted_3 = {
  key: 0,
  class: "top-block-item"
}
const _hoisted_4 = {
  key: 1,
  class: "top-block-item"
}
const _hoisted_5 = { class: "room-card-wr" }
const _hoisted_6 = { class: "room-card-top-front" }
const _hoisted_7 = { class: "room-card-top-front-title-wr" }
const _hoisted_8 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_9 = { class: "for-map1" }
const _hoisted_10 = { class: "for-map2" }
const _hoisted_11 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_12 = { class: "for-map3" }
const _hoisted_13 = {
  style: {"font-size":"17px","font-weight":"700"},
  class: "locale-price"
}
const _hoisted_14 = { class: "for-map4" }
const _hoisted_15 = {
  style: {"font-size":"17px","font-weight":"700"},
  class: "locale-price"
}
const _hoisted_16 = { class: "room-card-bottom" }
const _hoisted_17 = { class: "room-adress card-info" }
const _hoisted_18 = { class: "options-wr" }
const _hoisted_19 = { class: "card-address card-info" }
const _hoisted_20 = { class: "card-metro card-info" }
const _hoisted_21 = { class: "card-size card-info" }

import {RealtyObject} from "@/shared/api/gproperty-service";
import {onMounted} from "vue";
import {formatCurrency} from "../../../shared/utils";

type Props = {
  commonData: RealtyObject;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'card',
  props: {
    commonData: {}
  },
  setup(__props: any) {

const props = __props;

onMounted(() => {
  console.log('card: ', props.commonData)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("a", {
    href: `/realty-object/${_ctx.commonData.id}`,
    class: "card-wr-a"
  }, [
    (_ctx.commonData.payback || _ctx.commonData.income_per_month)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.commonData.payback)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[0] || (_cache[0] = _createTextVNode("Окупаемость ")),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.commonData.payback) + " лет", 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.commonData.income_per_month)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[2] || (_cache[2] = _createTextVNode("Доход ")),
                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(" " + _toDisplayString(_unref(formatCurrency)(_ctx.commonData.income_per_month)) + " ₽/МЕС", 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", {
        class: "room-card-top",
        style: _normalizeStyle({backgroundImage: `url(${props.commonData.photos[0]})`})
      }, null, 4),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.commonData.selling_price ? 'Цена' : 'Аренда в месяц') + ": ", 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.commonData.selling_price ? 'Цена за м²' : 'Цена за м² в год') + ": ", 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(formatCurrency)(_ctx.commonData.selling_price || _ctx.commonData.rent_per_month)), 1),
              _cache[4] || (_cache[4] = _createElementVNode("span", { style: {"font-size":"17px","color":"#ff3b3b"} }, " ₽ ", -1))
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(formatCurrency)(_ctx.commonData.selling_price_m2 || _ctx.commonData.rent_price_m2_year)), 1),
              _cache[5] || (_cache[5] = _createElementVNode("span", { style: {"font-size":"17px","color":"#ff3b3b"} }, " ₽ ", -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { style: {"display":"flex","justify-content":"space-between","position":"absolute","top":"10px","left":"0","padding":"5px","width":"100%"} }, null, -1)),
        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.commonData.title), 1),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.commonData.full_address), 1),
          _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.commonData.underground) + " (" + _toDisplayString(_ctx.commonData.minute_to_underground) + ") ", 1),
          _createElementVNode("div", _hoisted_21, " Площадь - " + _toDisplayString(_ctx.commonData.total_area) + " м² ", 1),
          _cache[6] || (_cache[6] = _createElementVNode("div", {
            id: "asId",
            style: {"display":"none"}
          }, "81", -1)),
          _cache[7] || (_cache[7] = _createElementVNode("div", {
            id: "premType",
            style: {"display":"none"}
          }, "Офисное помещение", -1))
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("button", {
          id: "goToCardBtn",
          style: {"display":"none"}
        }, "Перейти", -1))
      ])
    ])
  ], 8, _hoisted_1))
}
}

})