<script setup lang="ts">
import DefaultButton from "@/shared/components/default-button";
import {ref} from "vue";
import MapModal from "@/features/map-modal/ui/map-modal.vue";
import {RealtyObject} from "@/shared/api/gproperty-service";
import InputDropdown from "@/shared/components/input-dropdown/ui/input-dropdown.vue";
import {useRouter} from "vue-router";
const defaultFilters = {
  purpose: 'Арендный бизнес',
  premises_type: null,
  region: null,
}

const router = useRouter()

type Props = {
  cards: RealtyObject[];
  isLoading: boolean
};

const areaRange = ref({
  minValue: null,
  maxValue: null
});

const priceRange = ref({
  minValue: null,
  maxValue: null
});

const select1 = ref(null);
const select2 = ref(null);
const select3 = ref(null);

const selectItem = (item: any) => {
  filters.value.purpose = item.title; // Устанавливаем выбранный элемент
  select1.value?.blur()
};
const selectItem2 = (item: any) => {
  filters.value.premises_type = item.title; // Устанавливаем выбранный элемент
  select2.value?.blur()
};
const selectItem3 = (item: any) => {
  filters.value.region = item.title; // Устанавливаем выбранный элемент
  select3.value?.blur()
};

const props = defineProps<Props>();

const filters = ref({...defaultFilters})

const isMapModalOpen = ref(false)

const onMapOpen = () => {
  isMapModalOpen.value = true
}

const onSearchClick = async () => {
  await router.push({
    name: 'catalog',
    query: {
      page_number: 1,
      ...filters.value,
      price_from: priceRange.value.minValue,
      price_to: priceRange.value.maxValue,
      total_area_from: areaRange.value.minValue,
      total_area_to: areaRange.value.maxValue
    }
  });

}
</script>

<template>
  <div>
    <div class="filter-wr">
      <div class="filters">
        <v-select
            ref="select1"
            v-model="filters.purpose"
            class="custom-select"
            placeholder="Цель"
            :items="['Продажа', 'Аренда', 'Арендный бизнес']"
            variant="outlined"
        >
          <template #item="{ item }">
            <div class="custom-item" @click="selectItem(item)">
              <span>{{item.title}}</span>
            </div>
          </template>
          <template #selection="{ item, index }">
            <div class="custom-selection">
              <span>{{ item.title }}</span>
            </div>
          </template>
        </v-select>
        <v-select
            ref="select2"
            v-model="filters.premises_type"
            placeholder="Тип помещения"
            :items="['Торговое помещение', 'Здание', 'Офисное помещение']"
            variant="outlined"
        >
          <template #item="{ item }">
            <div class="custom-item"  @click="selectItem2(item)">
              <span>{{item.title}}</span>
            </div>
          </template>
          <template #selection="{ item, index }">
            <div class="custom-selection">
              <span>{{ item.title }}</span>
            </div>
          </template>
        </v-select>
        <v-select
            ref="select3"
            v-model="filters.region"
            placeholder="Любой город"
            :items="['Любой город', 'Москва', 'Подмосковье']"
            variant="outlined"
        >
          <template #item="{ item }">
            <div class="custom-item" @click="selectItem3(item)">
              <span>{{item.title}}</span>
            </div>
          </template>
          <template #selection="{ item, index }">
            <div class="custom-selection">
              <span>{{ item.title }}</span>
            </div>
          </template>
        </v-select>
        <InputDropdown v-model="areaRange" label="Площадь, м²" />
        <InputDropdown v-model="priceRange" label="Стоимость, ₽"  />
      </div>
      <div class="bot-buttons">
        <DefaultButton @click="onMapOpen">На карте</DefaultButton>
        <DefaultButton filled @click="onSearchClick">Поиск</DefaultButton>
      </div>
    </div>
  </div>
  <MapModal v-if="isMapModalOpen" :isLoading="isLoading" :cards="cards" @close="isMapModalOpen = false" />
</template>

<style scoped>
@media (max-width: 500px) {
  .filter-wr {
    width: 90% !important;
    height: fit-content !important;
  }
  .filters {
    display: flex !important;
    flex-direction: column;
    gap: 20px !important;
  }
}
.filter-wr {
  display: flex;
  gap: 10px;
  flex-direction: column;
  width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding: 14px 20px;
  height: 169px;
  border-radius: 40px;
  background-color: white;
  margin-top: -50px;
  padding-top: 30px;
}

.bot-buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
::v-deep .v-input__control::after {
  content: '▼'; /* Символ стрелочки */
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 10px;
  opacity: 30%;
  transform: translateY(-50%);
  pointer-events: none; /* Чтобы не было взаимодействия с стрелочкой */
  color: #000; /* Цвет стрелочки */
}


.filters {
  display: flex;
  gap: 10px;
  padding-left: 50px;
  padding-right: 50px;
}

::v-deep .v-input__control {
  position: relative;
  height: 30px;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  outline: none;
  border: none;
}

:deep(.v-input__details) {
  display: none;

}

:deep(.v-field__input) {
  height: 30px;
  padding: 3px 0 0 10px;
  outline: none;
  border: none;
  width: 150px;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
}

.custom-select {
  border-radius: 8px;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
}

.custom-item {
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  height: 42px;
  border-bottom: 1px solid #dcdcdc;
  box-sizing: border-box;
  padding-top: 11px;
  padding-left: 24px;
  background-color: white;
  color: #000;
}

.custom-item:hover {
  background-color: #ff3b3b;
  color: white;
  cursor: pointer;
}

.custom-selection {
  color: black;
  margin-top: -30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
