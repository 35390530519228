import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner"
}
const _hoisted_2 = {
  key: 1,
  class: "inside-page-card-wr"
}
const _hoisted_3 = {
  class: "main-title main-title_inside",
  style: {"text-transform":"uppercase"}
}
const _hoisted_4 = {
  class: "card-lot-title",
  style: {"text-transform":"uppercase"}
}
const _hoisted_5 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_6 = { style: {"display":"flex","font-size":"16px"} }
const _hoisted_7 = {
  class: "inside-adress",
  style: {"margin-right":"40px","margin-left":"40px"}
}
const _hoisted_8 = { class: "inside-metro" }
const _hoisted_9 = {
  style: {"display":"flex","width":"100%","margin-top":"40px"},
  class: "slider-and-desc-wr-media"
}
const _hoisted_10 = { class: "slider-and-desc-wr" }
const _hoisted_11 = { class: "slider-inside" }
const _hoisted_12 = {
  class: "card-description",
  style: {"margin-bottom":"20px"}
}
const _hoisted_13 = { class: "card-description-text" }
const _hoisted_14 = {
  class: "description-wr",
  style: {"margin-left":"30px","width":"100%"}
}
const _hoisted_15 = { class: "top-desc" }
const _hoisted_16 = { class: "desc-list-item" }
const _hoisted_17 = { class: "desc-list-item" }
const _hoisted_18 = { style: {"font-size":"16px","font-weight":"600"} }
const _hoisted_19 = { class: "locale-price" }
const _hoisted_20 = { style: {"font-size":"16px","font-weight":"600"} }
const _hoisted_21 = { class: "locale-price" }
const _hoisted_22 = { class: "desc-list-item desc-list-item_square" }
const _hoisted_23 = {
  style: {"font-weight":"600"},
  id: "squareItem"
}
const _hoisted_24 = { class: "desc-list-item desc-list-item_type" }
const _hoisted_25 = {
  style: {"font-weight":"600"},
  id: "typeItem"
}
const _hoisted_26 = { class: "desc-list-item desc-list-item_floor" }
const _hoisted_27 = {
  style: {"font-weight":"600"},
  id: "floorItem"
}
const _hoisted_28 = {
  key: 0,
  class: "desc-list-item desc-list-item_floor-count"
}
const _hoisted_29 = { style: {"font-weight":"600"} }
const _hoisted_30 = { class: "desc-list-item desc-list-item_layout" }
const _hoisted_31 = {
  style: {"font-weight":"600"},
  id: "layoutItem"
}
const _hoisted_32 = { class: "desc-list-item desc-list-item_paint" }
const _hoisted_33 = { style: {"font-weight":"600"} }
const _hoisted_34 = {
  key: 1,
  class: "desc-list-item desc-list-item_vit"
}
const _hoisted_35 = { style: {"font-weight":"600"} }
const _hoisted_36 = {
  key: 2,
  class: "desc-list-item desc-list-item_cel"
}
const _hoisted_37 = { style: {"font-weight":"600"} }
const _hoisted_38 = {
  key: 3,
  class: "desc-list-item desc-list-item_vit"
}
const _hoisted_39 = {
  style: {"font-weight":"600"},
  id: "vitItem"
}
const _hoisted_40 = { class: "desc-list-item desc-list-item_parking" }
const _hoisted_41 = {
  style: {"font-weight":"600"},
  id: "parkingItem"
}
const _hoisted_42 = {
  key: 4,
  class: "desc-list-item desc-list-item_parking"
}
const _hoisted_43 = {
  style: {"font-weight":"600"},
  id: "parkingItem"
}
const _hoisted_44 = {
  key: 5,
  class: "desc-list-item desc-list-item_parking"
}
const _hoisted_45 = {
  style: {"font-weight":"600"},
  id: "parkingItem"
}
const _hoisted_46 = { class: "bot-desc" }
const _hoisted_47 = { style: {"font-size":"14px"} }
const _hoisted_48 = {
  class: "desc-list-item",
  style: {"margin-top":"10px"}
}
const _hoisted_49 = { style: {"font-weight":"600"} }
const _hoisted_50 = {
  key: 0,
  class: "desc-list-item",
  style: {"margin-top":"15px"},
  id: "tenantListItem"
}
const _hoisted_51 = {
  style: {"font-weight":"600"},
  id: "tenant"
}
const _hoisted_52 = {
  class: "desc-list-item",
  style: {"margin-top":"15px"},
  id: "rentContractTermListItem"
}
const _hoisted_53 = {
  style: {"font-weight":"600"},
  id: "rentContractTerm"
}
const _hoisted_54 = {
  key: 1,
  class: "desc-list-item",
  style: {"margin-top":"15px"}
}
const _hoisted_55 = { style: {"font-weight":"600"} }
const _hoisted_56 = {
  key: 2,
  class: "desc-list-item",
  style: {"margin-top":"15px"}
}
const _hoisted_57 = { style: {"font-weight":"600"} }
const _hoisted_58 = { id: "dohod" }
const _hoisted_59 = {
  key: 3,
  class: "desc-list-item",
  style: {"margin-top":"15px"}
}
const _hoisted_60 = { style: {"font-weight":"600"} }
const _hoisted_61 = {
  key: 2,
  class: "red-title",
  style: {"margin-top":"40px","margin-bottom":"40px"}
}
const _hoisted_62 = {
  key: 3,
  class: "room-cards-wr"
}

import {onMounted, ref} from "vue";
import {
  RealtyObject,
  useApiRequest,
  useApiRequestSimilarOffers
} from "@/shared/api/gproperty-service";
import {formatCurrency} from "../../../shared/utils";
import {useRouter} from "vue-router";
import Card from "@/entities/card/ui/card.vue";
import CardListSkeleton from "@/shared/ui/card-list-skeleton.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'realty-object',
  setup(__props) {


const { result, isLoading, error, makeRequest } = useApiRequest();
const {
  result: resultSimilarOffers,
  isLoading: isLoadingSimilarOffers,
  makeRequest: makeRequestSimilarOffers
} = useApiRequestSimilarOffers();

const realtyObj = ref<RealtyObject>()
const photoIndex = ref(0)
const similarOffers = ref<RealtyObject[]>([])


const router = useRouter()

onMounted(async () => {
  await makeRequest(router.currentRoute.value.params.id as string)
  realtyObj.value = {...result.value?.result as RealtyObject}
  if (result.value?.result?.id) {
    await makeRequestSimilarOffers({'realty_object_id': result.value?.result?.id})
    similarOffers.value = resultSimilarOffers.value?.result ? [...resultSimilarOffers.value?.result as RealtyObject[]] : []
  } else {
    similarOffers.value = []
  }

})

const onRightArrow = () => {
  if (realtyObj.value?.photos?.length && photoIndex.value < realtyObj.value?.photos.length -1) {
    photoIndex.value++
  } else {
    photoIndex.value = 0
  }
}

const onLeftArrow = () => {
  if (realtyObj.value?.photos?.length && photoIndex.value > 0) {
    photoIndex.value--
  } else {
    photoIndex.value = realtyObj.value?.photos?.length ? realtyObj.value?.photos.length - 1 : 0
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(realtyObj.value?.title), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(realtyObj.value?.premises_type) + " (ЛОТ " + _toDisplayString(realtyObj.value?.lot) + ")", 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(realtyObj.value?.full_address), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(realtyObj.value?.underground), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", {
                  class: "slider-item",
                  style: _normalizeStyle([{backgroundImage: `url(${realtyObj.value?.photos[photoIndex.value]})`}, {"width":"652px","height":"460px","display":"block","transition":"all 0.5s ease 0.5s","background-repeat":"no-repeat","background-size":"contain","background-position":"center center"}])
                }, null, 4),
                _createElementVNode("div", {
                  class: "right-arrow",
                  onClick: onRightArrow
                }),
                _createElementVNode("div", {
                  class: "left-arrow",
                  onClick: onLeftArrow
                })
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "get-help-btn" }, "Получить консультацию", -1)),
              _createElementVNode("div", _hoisted_12, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-description-title" }, " Описание ", -1)),
                _createElementVNode("div", _hoisted_13, _toDisplayString(realtyObj.value?.description), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("ul", null, [
                  _createElementVNode("li", _hoisted_16, [
                    _createElementVNode("div", null, _toDisplayString(realtyObj.value?.selling_price ? 'Цена' : 'Аренда в месяц') + ": ", 1),
                    _createElementVNode("div", null, _toDisplayString(realtyObj.value?.selling_price ? 'Цена за м²' : 'Цена за м² в год') + ": ", 1)
                  ]),
                  _createElementVNode("li", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(formatCurrency)(realtyObj.value?.rent_per_month || realtyObj.value?.selling_price)), 1),
                      _cache[2] || (_cache[2] = _createElementVNode("span", { style: {"color":"#ff3b3b"} }, " ₽", -1))
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(formatCurrency)(realtyObj.value?.rent_price_m2_year || realtyObj.value?.selling_price_m2)), 1),
                      _cache[3] || (_cache[3] = _createElementVNode("span", { style: {"color":"#ff3b3b"} }, " ₽", -1))
                    ])
                  ]),
                  _createElementVNode("li", _hoisted_22, [
                    _cache[4] || (_cache[4] = _createElementVNode("div", null, " Площадь: ", -1)),
                    _createElementVNode("div", _hoisted_23, _toDisplayString(realtyObj.value?.total_area) + " м² ", 1)
                  ]),
                  _createElementVNode("li", _hoisted_24, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", null, " Тип здания: ", -1)),
                    _createElementVNode("div", _hoisted_25, _toDisplayString(realtyObj.value?.building_type), 1)
                  ]),
                  _createElementVNode("li", _hoisted_26, [
                    _cache[6] || (_cache[6] = _createElementVNode("div", null, " Этаж: ", -1)),
                    _createElementVNode("div", _hoisted_27, _toDisplayString(realtyObj.value?.floor), 1)
                  ]),
                  (realtyObj.value?.floor_quantity)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_28, [
                        _cache[7] || (_cache[7] = _createElementVNode("div", null, " Количество этажей: ", -1)),
                        _createElementVNode("div", _hoisted_29, _toDisplayString(realtyObj.value?.floor_quantity), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", _hoisted_30, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", null, " Планировка: ", -1)),
                    _createElementVNode("div", _hoisted_31, _toDisplayString(realtyObj.value?.layout), 1)
                  ]),
                  _createElementVNode("li", _hoisted_32, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", null, " Отделка: ", -1)),
                    _createElementVNode("div", _hoisted_33, _toDisplayString(realtyObj.value?.finishing), 1)
                  ]),
                  (realtyObj.value?.electricity_power)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_34, [
                        _cache[10] || (_cache[10] = _createElementVNode("div", null, " Мощность (кВт): ", -1)),
                        _createElementVNode("div", _hoisted_35, _toDisplayString(realtyObj.value?.electricity_power), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (realtyObj.value?.ceiling_height)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_36, [
                        _cache[11] || (_cache[11] = _createElementVNode("div", null, " Высота потолков (м): ", -1)),
                        _createElementVNode("div", _hoisted_37, _toDisplayString(realtyObj.value?.ceiling_height), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (realtyObj.value?.hood)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_38, [
                        _cache[12] || (_cache[12] = _createElementVNode("div", null, " Вытяжка: ", -1)),
                        _createElementVNode("div", _hoisted_39, _toDisplayString(realtyObj.value?.hood), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", _hoisted_40, [
                    _cache[13] || (_cache[13] = _createElementVNode("div", null, " Парковка: ", -1)),
                    _createElementVNode("div", _hoisted_41, _toDisplayString(realtyObj.value?.parking), 1)
                  ]),
                  (realtyObj.value?.parking_space_quantity)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_42, [
                        _cache[14] || (_cache[14] = _createElementVNode("div", null, " Количество мест: ", -1)),
                        _createElementVNode("div", _hoisted_43, _toDisplayString(realtyObj.value?.parking_space_quantity), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (realtyObj.value?.parking_space_cost)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_44, [
                        _cache[15] || (_cache[15] = _createElementVNode("div", null, " Количество мест: ", -1)),
                        _createElementVNode("div", _hoisted_45, _toDisplayString(realtyObj.value?.parking_space_cost), 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_46, [
                _cache[24] || (_cache[24] = _createElementVNode("div", { class: "bot-desc-title" }, " Коммерческие условия ", -1)),
                _createElementVNode("ul", _hoisted_47, [
                  _createElementVNode("li", _hoisted_48, [
                    _cache[16] || (_cache[16] = _createElementVNode("div", null, " Торг: ", -1)),
                    _createElementVNode("div", _hoisted_49, _toDisplayString(realtyObj.value?.bargain), 1)
                  ]),
                  (realtyObj.value?.tenant)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_50, [
                        _cache[17] || (_cache[17] = _createElementVNode("div", null, " Арендатор: ", -1)),
                        _createElementVNode("div", _hoisted_51, _toDisplayString(realtyObj.value?.tenant), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", _hoisted_52, [
                    _cache[18] || (_cache[18] = _createElementVNode("div", null, " Срок договора аренды: ", -1)),
                    _createElementVNode("div", _hoisted_53, _toDisplayString(realtyObj.value?.rent_contract_term), 1)
                  ]),
                  (realtyObj.value?.tax)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_54, [
                        _cache[19] || (_cache[19] = _createElementVNode("div", null, " Налоги: ", -1)),
                        _createElementVNode("div", _hoisted_55, _toDisplayString(realtyObj.value?.tax), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (realtyObj.value?.income_per_month)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_56, [
                        _cache[22] || (_cache[22] = _createElementVNode("div", null, " Доход: ", -1)),
                        _createElementVNode("div", _hoisted_57, [
                          _cache[20] || (_cache[20] = _createTextVNode(" Доход ")),
                          _createElementVNode("span", _hoisted_58, _toDisplayString(_unref(formatCurrency)(realtyObj.value?.income_per_month)), 1),
                          _cache[21] || (_cache[21] = _createTextVNode(" ₽/МЕС "))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (realtyObj.value?.payback)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_59, [
                        _cache[23] || (_cache[23] = _createElementVNode("div", null, " Окупаемость: ", -1)),
                        _createElementVNode("div", _hoisted_60, _toDisplayString(realtyObj.value?.payback) + " лет ", 1)
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])),
    (similarOffers.value?.length || _unref(isLoadingSimilarOffers))
      ? (_openBlock(), _createElementBlock("div", _hoisted_61, "Похожие предложения"))
      : _createCommentVNode("", true),
    (_unref(isLoadingSimilarOffers) || similarOffers.value?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
          (!_unref(isLoadingSimilarOffers) && similarOffers.value?.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(similarOffers.value, (offer) => {
                return (_openBlock(), _createBlock(Card, {
                  commonData: offer,
                  key: offer.id
                }, null, 8, ["commonData"]))
              }), 128))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, _renderList(4, (i) => {
                return _createVNode(CardListSkeleton)
              }), 64))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})