import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer-wr" }
const _hoisted_2 = { class: "footer-main" }
const _hoisted_3 = { class: "footer-nav-wr" }
const _hoisted_4 = { class: "footer-nav-list" }
const _hoisted_5 = { class: "footer-list" }
const _hoisted_6 = { class: "footer-list" }

import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'footer-widget',
  setup(__props) {

const router = useRouter()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("footer", _hoisted_2, [
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "footer-logo" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "footer-nav-title" }, " Навигация ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              id: "footerBtn1",
              onClick: _cache[0] || (_cache[0] = () => _unref(router).push({name: 'home'}))
            }, " ГЛАВНАЯ "),
            _createElementVNode("div", {
              id: "footerBtn2",
              onClick: _cache[1] || (_cache[1] = () => _unref(router).push({name: 'about'}))
            }, " О КОМПАНИИ "),
            _createElementVNode("div", {
              id: "footerBtn3",
              onClick: _cache[2] || (_cache[2] = () => _unref(router).push({name: 'catalog', query: {purpose: 'Аренда'}}))
            }, " АРЕНДА "),
            _createElementVNode("div", {
              id: "footerBtn4",
              onClick: _cache[3] || (_cache[3] = () => _unref(router).push({name: 'catalog', query: {purpose: 'Продажа'}}))
            }, " ПРОДАЖА ")
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              id: "footerBtn5",
              onClick: _cache[4] || (_cache[4] = () => _unref(router).push({name: 'partners'}))
            }, " ПАРТНЕРАМ "),
            _createElementVNode("div", {
              id: "footerBtn6",
              onClick: _cache[5] || (_cache[5] = () => _unref(router).push({name: 'contacts'}))
            }, " СОБСТВЕННИКАМ "),
            _createElementVNode("div", {
              id: "footerBtn7",
              onClick: _cache[6] || (_cache[6] = () => _unref(router).push({name: 'contacts'}))
            }, " КЛИЕНТАМ "),
            _createElementVNode("div", {
              id: "footerBtn8",
              onClick: _cache[7] || (_cache[7] = () => _unref(router).push({name: 'catalog', query: {purpose: 'Арендный бизнес'}}))
            }, " АРЕНДНЫЙ БИЗНЕС ")
          ])
        ])
      ]),
      _cache[10] || (_cache[10] = _createStaticVNode("<div data-v-3de7aa03><div class=\"footer-nav-title\" data-v-3de7aa03> Контакты </div><div class=\"footer-contacts-list\" data-v-3de7aa03><a class=\"footer-phone\" href=\"tel:+79779846661\" data-v-3de7aa03>+7 915 337 66 65</a><a class=\"footer-mail\" href=\"mailto:info@aprope.ru\" data-v-3de7aa03>info@aprope.ru</a><a class=\"footer-adress\" href=\"https://yandex.ru/maps/213/moscow/house/yaroslavskoye_shosse_114/Z04YcQdmT0wDQFtvfXR3d3pqYw==/?ll=37.706694%2C55.866698&amp;z=17.45\" data-v-3de7aa03>г. Москва, Ярославское шоссе 114</a></div></div><div data-v-3de7aa03><div style=\"display:flex;width:150px;justify-content:space-between;padding-bottom:10px;border-bottom:3px solid #ff3b3b;margin-top:10px;\" data-v-3de7aa03><a class=\"footer-user\" href=\"https://app.aprope.ru/\" data-v-3de7aa03></a><a class=\"footer-buy\" href=\"tel:+79153376665\" data-v-3de7aa03></a><a class=\"footer-telegram\" href=\"tg://resolve?domain=+79153376665\" data-v-3de7aa03></a><a class=\"footer-website\" href=\"tel:+79779846661\" data-v-3de7aa03></a></div></div>", 2))
    ])
  ]))
}
}

})